<template>

  <!-- Format Settings-->
  <div v-if="format">

    <a-parameter-slider
        v-if="!block.config.show_sidebar"
        @change="updateSettings(); "
        v-model="block.config.text_width"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="avalon.block.text.text_width"
        min="600"
        default="700"
        :max="block.config.show_sidebar ? (block.config.width - (block.config.navigation_width || 350) ) : block.config.width "
        class="mt-4"
        light
    />

    <a-parameter-slider
        v-if="block.config.show_sidebar"
        @change="updateSettings(); "
        v-model="block.config.navigation_width"
        :track-color="wsDARKLIGHT"
        :value-color="wsACCENT"
        label="avalon.block.text.navigation_width"
        min="200"
        default="350"
        max="500"
        class="mt-4"
        light
    />


  </div>

  <!-- Content Settings-->
  <div v-else-if="!isStyle">

    <ws-text-field
        @change="updateSettings"
        v-model="block.config.calendar_title"
        :label="$t('Title')"
        :placeholder="$t('EnterBlockTitle')"
        avalon-style
        clearable
    />

    <ws-select
        @input="updateSettings"
        v-model="block.config.all_items_page"
        :label="$t('all_items_page')"
        :placeholder="$t('ChoosePage')"
        :items="[{ icon : 'mdi-circle-outline' ,  text : $t('NoDynamicTileButton') , value : null} , ...CURRENT_LANG_PAGES]"
        avalon-style
        clearable
        class="mt-5"
    />

    <ws-text-field
        v-if="block.config.all_items_page"
        class="mt-5"
        @change="updateSettings"
        v-model="block.config.all_items_page_text"
        :label="$t('all_items_page_text')"
        :placeholder="$t('All')"
        avalon-style
        clearable
    />



  </div>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: "blockTilesSettings",
  components : {},
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() { return {} }
    },
    design : {
      type : Boolean,
      default : false
    },
    format : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      selectedItemIndex : null ,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
    }
  },
  computed : {
    ...mapState('avalon',['course' , 'lang', 'pagesSelect' , 'coursesSelect']),
    displayNavigationSelect() {
      return [
        { text : this.$t('Display') , value : true  },
        { text : this.$t('Hide') , value : false  }
      ]
    },

    buttonsIconsSelect() {
      return [
        { text : this.$t('Arrow') , value : 'mdi-arrow-right'  , right_icon : 'mdi-arrow-right' },
        { text : this.$t('Plus') , value : 'mdi-plus' , right_icon : 'mdi-plus' },
      ]
    },
    imageAccordeonItems() {
      return [
          { name : this.$t('Button') , value : 'button' , expanded : true },
          { name : this.$t('Image') , value : 'image'   }
      ]
    },
    itemsPerPageSelect() {
      return [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 }
      ]
    },
    buttonActionsSelect() {
      return [
        { text : this.$t('OpenDetailsSidebar') , value : "sidebar" },
        { text : this.$t('OpenDetailsDialog') , value : "dialog" },
        { text : this.$t('OpenLink') , value : "link" },
        // { text : this.$t('RegisterOnCourse') , value : "course" },
      ]
    },
    sliderContentSelect() {
      return [
        { text: this.$t('Title'), value: 'heading' },
        { text: this.$t('Content'), value: 'items' },
        { text: this.$t('EditItem'), value: 'edit_item', hidden: true}
      ]
    },

    selectedDesignName() {
      if ( !this.block.config.design ) {
        return this.designSelect[0].text
      }
      let design = this.designSelect.find(el => el.value === this.block.config.design)

      if (!design ) {
        return this.designSelect[0].text
      }

      return design.text

    },
    designSelect() {
      return [
        { text : this.$t('Style') + ' 1' , value : 'style_1' },
        { text : this.$t('Style') + ' 2' , value : 'style_2' }
      ]
    }
  },
  methods : {
    addImage() {
      this.block.config.elements.push( { img : null })
      this.updateSettings()
    },
    changeButtonStyle(value) {
      this.block.config.elements[this.selectedItemIndex].style_button = value
      this.updateSettings()
    },

    updateSettings() {
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    deleteItem(index) {
      this.block.config.elements.splice(index,1)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    },
    duplicateItem(index) {
      let item =  this.COPY(this.block.config.elements[index])
      this.block.config.elements.splice(index , 0 , item)
      this.block = this.COPY(this.block)
      this.$emit('input' , this.COPY(this.block.config))
    }

  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>